
:root{
  --margin: 20px;
  --bold: 600;
  --page-width: 1000px;

  --light-body-bg: #e1e1e1;
  --light-bg: #f2f2f2;
  --light-bg2: #fff;
  --light-fore: #333;

  --dark-body-bg: #1a1a1a;
  --dark-bg: #3d3b3b;
  --dark-bg2: #333;
  --dark-fore: #bfbfbf;

  --light-highlight-bg: #355435;
  --dark-highlight-bg: rgb(52, 69, 53);

  --light-a: rgb(139, 163, 140);
  --dark-a: rgb(181, 217, 183);

  --print-color: black;

  --image-size: 64px;
}

/* Generic and utils */

body,
.app {
  background: var(--light-body-bg); line-height: 1.5em;
  font-family: Arial; color: var(--light-fore)
}

a {color: var(--light-a);}

.square-logo{
  width: var(--image-size); height: var(--image-size);
  margin-left: 30px; margin-right: 5px;
  position: absolute; right: 15px; bottom: 15px;
  background-position: center; background-repeat: no-repeat;
  background-size: var(--image-size) var(--image-size);
  z-index: 10;
}
.square-logo img{ visibility: hidden;}

.relative { position: relative }

.items { display: block; margin-top: 15px; margin-right: 64px; }
ul{ list-style: none; padding-inline-start: 0px; }
ul li { padding-bottom: 4px; position: relative;  }

.center-container{ max-width: var(--page-width); margin: 0 auto; background: var(--light-bg2); padding: var(--margin)}

/* Header */

header.main{
  background-color: var(--light-highlight-bg); display: block;
  margin: -20px; padding: 20px; padding-bottom: 10px; margin-bottom: 20px;
  color: var(--light-bg2);
}

header .square-logo {
  width: 128px; height: 128px; margin-top: 0;
  background-size: 128px; position:relative; float: right;
}
.dark header .square-logo { background-color: transparent; }

header .square-logo .print-image {width: 128px; height: 128px;}

.print-image{ width: var(--image-size); height: var(--image-size)}

.printer {
  position: fixed;
  bottom: 0; right: 80px; padding: 10px;
  height: 30px; width: 30px; line-height: 20px;
  background: #f2f2f259;
  border-top-left-radius: 30px; border-top-right-radius: 30px;
  text-align: center; display: block;cursor: pointer;
}
.printer svg { opacity: 0.8;}
.printer svg path, .printer svg rect { fill: #454c54 }
.printer:hover svg { opacity: 1;}


/* Contact Box */

h1.name{ font-size: 150%; }

h3{
  margin-top: 20px;
  margin-left: -20px; margin-right: -20px; padding: 10px; padding-left: 20px; padding-right: 20px;
  background-color: var(--light-highlight-bg); display: block; color: var(--light-bg2);
}


.contact{ float: right; text-align: right; display: block; margin-bottom: var(--margin); margin-top: 2px; }
.contact > span, .contact > a { display: block;}
.contact .cvlink{ display: none; }

/* Content Specific */

.techlist{ width: 50%; display: flex; }

.techsperiencex article > ul > li {
  background: var(--light-bg); padding: 10px; margin-bottom: 20px
}

.skills,
.position {font-style: italic;}
.tagline, .keywords {display: block;}

.tagline { margin-bottom: 5px; font-weight: normal;}
.tiny {font-size: 12px;}

.date, .place, .keywords { font-size: 90% }

.place, .date {float: right}

.name{ display: block; font-weight: 600; font-size: 100%; margin-bottom: 2px; }

.techlistflex { display: flex; }



/* Small Screen*/

@media (max-width: 500px) {
  .square-logo{
    position: relative; float: right; top: 10px; right: 10px;
  }
  .techsperiencex article { margin-left: -20px; margin-right: -20px; }
  .place, .date {float: none}
  header .square-logo { display: none; }
  .techlist{ width: 100%; display: block; }
  .techlistflex{ display: block;}
}


/* Dark theme */

.dark body,
.dark { background: var(--dark-body-bg); color: var(--dark-fore) }
.dark .center-container{ background: var(--dark-bg2);}
.dark .techsperiencex article > ul > li {background: var(--dark-bg)}
.dark h3{background-color: var(--dark-highlight-bg);}
.dark header.main{ background-color: var(--dark-highlight-bg); }
.dark a {color: var(--dark-a);}
.dark .square-logo{background-color: #fff3}

/* Drawings */
.no-images .square-logo { display: none;}

@media print {
  body{ font-size: 80% }
  h3{ margin-top: 15px; margin-bottom: 0px; margin-left: -10px; padding-left: 10px; }
  .experience > ul > li { padding: 0;}

  .printer{ display: none; }

  body{ background: white; }
  .center-container{ background: var(--light-bg2);}
  .techsperiencex article > ul > li {background: var(--light-bg)}

  h1, h2, h3{ color: white }

  header.main{ background-color: var(--light-highlight-bg); }
  a {color: var(--light-a);}

  .square-logo {margin-right: -10px}
  .square-logo img{ visibility: visible; width: var(--image-size); z-index: -1;}
  .hideprint {display: none !important;}

  /* Dark, no header BG colors */

  .dark h1, .dark h2, .dark h3{ color: #335 }
  .dark body, .center-container {color: #222 }
  .dark .techsperiencex article > ul > li {padding-left: 0}

  .contact{margin-top: -8px;}
  header .square-logo {margin-top: -11px }
  .contact .cvlink { display: inline-block}
}