:where(html) {
  --ease-1: cubic-bezier(.25, 0, .5, 1);
  --ease-2: cubic-bezier(.25, 0, .4, 1);
  --ease-3: cubic-bezier(.25, 0, .3, 1);
  --ease-4: cubic-bezier(.25, 0, .2, 1);
  --ease-5: cubic-bezier(.25, 0, .1, 1);
  --ease-in-1: cubic-bezier(.25, 0, 1, 1);
  --ease-in-2: cubic-bezier(.50, 0, 1, 1);
  --ease-in-3: cubic-bezier(.70, 0, 1, 1);
  --ease-in-4: cubic-bezier(.90, 0, 1, 1);
  --ease-in-5: cubic-bezier(1, 0, 1, 1);
  --ease-out-1: cubic-bezier(0, 0, .75, 1);
  --ease-out-2: cubic-bezier(0, 0, .50, 1);
  --ease-out-3: cubic-bezier(0, 0, .3, 1);
  --ease-out-4: cubic-bezier(0, 0, .1, 1);
  --ease-out-5: cubic-bezier(0, 0, 0, 1);
  --ease-in-out-1: cubic-bezier(.1, 0, .9, 1);
  --ease-in-out-2: cubic-bezier(.3, 0, .7, 1);
  --ease-in-out-3: cubic-bezier(.5, 0, .5, 1);
  --ease-in-out-4: cubic-bezier(.7, 0, .3, 1);
  --ease-in-out-5: cubic-bezier(.9, 0, .1, 1);
  --ease-elastic-out-1: cubic-bezier(.5, .75, .75, 1.25);
  --ease-elastic-out-2: cubic-bezier(.5, 1, .75, 1.25);
  --ease-elastic-out-3: cubic-bezier(.5, 1.25, .75, 1.25);
  --ease-elastic-out-4: cubic-bezier(.5, 1.5, .75, 1.25);
  --ease-elastic-out-5: cubic-bezier(.5, 1.75, .75, 1.25);
  --ease-elastic-in-1: cubic-bezier(.5, -0.25, .75, 1);
  --ease-elastic-in-2: cubic-bezier(.5, -0.50, .75, 1);
  --ease-elastic-in-3: cubic-bezier(.5, -0.75, .75, 1);
  --ease-elastic-in-4: cubic-bezier(.5, -1.00, .75, 1);
  --ease-elastic-in-5: cubic-bezier(.5, -1.25, .75, 1);
  --ease-elastic-in-out-1: cubic-bezier(.5, -.1, .1, 1.5);
  --ease-elastic-in-out-2: cubic-bezier(.5, -.3, .1, 1.5);
  --ease-elastic-in-out-3: cubic-bezier(.5, -.5, .1, 1.5);
  --ease-elastic-in-out-4: cubic-bezier(.5, -.7, .1, 1.5);
  --ease-elastic-in-out-5: cubic-bezier(.5, -.9, .1, 1.5);
  --ease-step-1: steps(2);
  --ease-step-2: steps(3);
  --ease-step-3: steps(4);
  --ease-step-4: steps(7);
  --ease-step-5: steps(10);
  --ease-elastic-1: var(--ease-elastic-out-1);
  --ease-elastic-2: var(--ease-elastic-out-2);
  --ease-elastic-3: var(--ease-elastic-out-3);
  --ease-elastic-4: var(--ease-elastic-out-4);
  --ease-elastic-5: var(--ease-elastic-out-5);
  --ease-squish-1: var(--ease-elastic-in-out-1);
  --ease-squish-2: var(--ease-elastic-in-out-2);
  --ease-squish-3: var(--ease-elastic-in-out-3);
  --ease-squish-4: var(--ease-elastic-in-out-4);
  --ease-squish-5: var(--ease-elastic-in-out-5);
  --ease-spring-1: linear(0, 0.006, 0.025 2.8%, 0.101 6.1%, 0.539 18.9%, 0.721 25.3%, 0.849 31.5%, 0.937 38.1%, 0.968 41.8%, 0.991 45.7%, 1.006 50.1%, 1.015 55%, 1.017 63.9%, 1.001);
  --ease-spring-2: linear(0, 0.007, 0.029 2.2%, 0.118 4.7%, 0.625 14.4%, 0.826 19%, 0.902, 0.962, 1.008 26.1%, 1.041 28.7%, 1.064 32.1%, 1.07 36%, 1.061 40.5%, 1.015 53.4%, 0.999 61.6%, 0.995 71.2%, 1);
  --ease-spring-3: linear(0, 0.009, 0.035 2.1%, 0.141 4.4%, 0.723 12.9%, 0.938 16.7%, 1.017, 1.077, 1.121, 1.149 24.3%, 1.159, 1.163, 1.161, 1.154 29.9%, 1.129 32.8%, 1.051 39.6%, 1.017 43.1%, 0.991, 0.977 51%, 0.974 53.8%, 0.975 57.1%, 0.997 69.8%, 1.003 76.9%, 1);
  --ease-spring-4: linear(0, 0.009, 0.037 1.7%, 0.153 3.6%, 0.776 10.3%, 1.001, 1.142 16%, 1.185, 1.209 19%, 1.215 19.9% 20.8%, 1.199, 1.165 25%, 1.056 30.3%, 1.008 33%, 0.973, 0.955 39.2%, 0.953 41.1%, 0.957 43.3%, 0.998 53.3%, 1.009 59.1% 63.7%, 0.998 78.9%, 1);
  --ease-spring-5: linear(0, 0.01, 0.04 1.6%, 0.161 3.3%, 0.816 9.4%, 1.046, 1.189 14.4%, 1.231, 1.254 17%, 1.259, 1.257 18.6%, 1.236, 1.194 22.3%, 1.057 27%, 0.999 29.4%, 0.955 32.1%, 0.942, 0.935 34.9%, 0.933, 0.939 38.4%, 1 47.3%, 1.011, 1.017 52.6%, 1.016 56.4%, 1 65.2%, 0.996 70.2%, 1.001 87.2%, 1)
}

.theme-switcher-icon {
  position: fixed;
  bottom: 0; right: 10px; padding: 10px;
  height: 30px; width: 30px; line-height: 20px;
  background: #f2f2f259;
  border-top-left-radius: 30px; border-top-right-radius: 30px;
  text-align: center; display: block;cursor: pointer;
}

.sun-and-moon>:is(.moon, .sun, .sun-beams) {
  transform-origin: center center
}

.sun-and-moon>:is(.moon, .sun) {
  fill: var(--icon-fill)
}

.theme-toggle:is(:hover, :focus-visible)>.sun-and-moon>:is(.moon, .sun) {
  fill: var(--icon-fill-hover)
}

.sun-and-moon>.sun-beams {
  stroke: var(--icon-fill);
  stroke-width: 2px
}

.theme-toggle:is(:hover, :focus-visible) .sun-and-moon>.sun-beams {
  stroke: var(--icon-fill-hover)
}

[data-theme=dark] .sun-and-moon>.sun {
  transform: scale(1.75)
}

[data-theme=dark] .sun-and-moon>.sun-beams {
  opacity: 0
}

[data-theme=dark] .sun-and-moon>.moon>circle {
  transform: translate(-7px)
}

@supports (cx: 1) {
  [data-theme=dark] .sun-and-moon>.moon>circle {
    transform: translate(0);
    cx: 17
  }
}

@media (prefers-reduced-motion: no-preference) {
  .sun-and-moon>.sun {
    transition: transform .5s var(--ease-elastic-3)
  }

  .sun-and-moon>.sun-beams {
    transition: transform .5s var(--ease-elastic-4), opacity .5s var(--ease-3)
  }

  .sun-and-moon .moon>circle {
    transition: transform .25s var(--ease-out-5)
  }

  @supports (cx: 1) {
    .sun-and-moon .moon>circle {
      transition: cx .25s var(--ease-out-5)
    }
  }

  [data-theme=dark] .sun-and-moon>.sun {
    transform: scale(1.75);
    transition-timing-function: var(--ease-3);
    transition-duration: .25s
  }

  [data-theme=dark] .sun-and-moon>.sun-beams {
    transform: rotate(-25deg);
    transition-duration: .15s
  }

  [data-theme=dark] .sun-and-moon>.moon>circle {
    transition-delay: .25s;
    transition-duration: .5s
  }
}

.theme-toggle {
  --size: 2rem;
  --icon-fill: hsl(210 10% 30%);
  --icon-fill-hover: hsl(210 10% 15%);
  background: none;
  border: none;
  padding: 0;
  inline-size: var(--size);
  block-size: var(--size);
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
  touch-action: manipulation;
  -webkit-tap-highlight-color: transparent;
  outline-offset: 5px
}

.theme-toggle>svg {
  inline-size: 100%;
  block-size: 100%;
  stroke-linecap: round
}

[data-theme=dark] .theme-toggle {
  --icon-fill: hsl(210 10% 70%);
  --icon-fill-hover: hsl(210 15% 90%)
}